<template>
  <q-page class="column full-width" :style-fn="styleFn">
    <q-scroll-area
      class="full-width mask-overflow-tb-drawer"
      ref="scrollArea"
      :style="pageStyle"
    >
      <div class="q-pt-xl row scroll-section">
        <img
          alt="Shinola"
          class="q-mx-auto"
          src="~assets/shinola.svg"
          style="width: 150px"
        />
      </div>
      <template v-for="content in app.letter.content" :key="content._key">
        <div
          :class="$q.screen.gt.sm ? 'scroll-section window-height-min' : void 0"
          class="row items-center justify-center q-pb-xl"
        >
          <component :data="content" :is="isComponent(content._type)" />
        </div>
      </template>
      <template v-for="content in app.letter2.content" :key="content._key">
        <div
          :class="$q.screen.gt.sm ? 'scroll-section window-height-min' : void 0"
          class="row items-center justify-center q-pb-xl"
        >
          <component :data="content" :is="isComponent(content._type)" />
        </div>
      </template>
    </q-scroll-area>
  </q-page>
</template>

<script setup>
import { computed, nextTick, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";
import BodyComponent from "src/components/body/BodyComponent.vue";
import ProjectsComponent from "src/components/project/ProjectsComponent.vue";

defineOptions({ name: "IndexPage" });

const appStore = useAppStore();
const { app } = storeToRefs(appStore);
const pageHeight = ref("100vh");
const pageStyle = computed(() => {
  return { minHeight: pageHeight.value };
});
const scrollArea = ref(null);

const isComponent = (type) => {
  return type === "bodyBlock" ? BodyComponent : ProjectsComponent;
};

const setScrollContainerStyle = () => {
  const container = scrollArea.value?.$el?.querySelector(
    ".q-scrollarea__container"
  );
  if (container) {
    container.style.minHeight = pageHeight.value;
    container.style.scrollSnapType = "y mandatory";
  }
};

const styleFn = (offset) => {
  pageHeight.value = offset ? `calc(100vh - ${offset}px)` : "100vh";
};

watch(pageHeight, async () => {
  await nextTick();
  setScrollContainerStyle();
});
</script>
