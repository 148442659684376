<template>
  <q-dialog maximized ref="dialogRef" @hide="onDialogHide">
    <q-card class="transparent" :dark="false" square>
      <component
        :data="data"
        :is="isComponent(type)"
        :placeholder-src="placeholderSrc"
        :src="src"
      />
      <div
        :class="$q.screen.gt.sm ? 'q-mr-xl q-mt-xl' : 'q-mr-md q-mt-md'"
        class="absolute-top-right"
        style="z-index: 1"
      >
        <div class="backdrop-blur backdrop-dimmed">
          <q-btn
            dense
            color="primary"
            :icon="symSharpClose"
            :size="$q.screen.gt.sm ? 'md' : 'sm'"
            square
            text-color="dark"
            unelevated
            @click="onOKClick()"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from "quasar";
import { symSharpClose } from "@quasar/extras/material-symbols-sharp";
import LetterDialog from "src/components/letter/LetterDialog.vue";

const props = defineProps({
  data: { type: Object, default: () => {} },
  placeholderSrc: { type: String, default: "" },
  src: { type: String, default: "" },
  type: { type: String, default: "swiper" },
});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const isComponent = (type) => {
  switch (type) {
    default:
      return LetterDialog;
  }
};

function onOKClick() {
  onDialogOK();
}
</script>
